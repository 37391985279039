import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"

const HeroContainer = styled.div`
  position: relative;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 80px;
  }
`

const BackgroundImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  display: flex;

  div {
    max-width: unset !important;
    width: 100%;
    height: 100%;
  }
`

const MobileImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  @media (min-width: ${breakpoints.md}) {
    display: none !important;
  }
`

const TabletImage = styled(BackgroundImage)`
  display: none !important;
  @media (min-width: ${breakpoints.md}) {
    display: flex !important;
  }
  @media (min-width: ${breakpoints.lg}) {
    display: none !important;
  }
`

const DesktopImage = styled(BackgroundImage)`
  display: none !important;
  @media (min-width: ${breakpoints.lg}) {
    display: flex !important;
  }
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  top: 0%;
  left: 0;
  display: none;
  flex-direction: column;
  gap: 0;
  margin-left: 50px;
  @media (min-width: ${breakpoints.md}) {
    display: flex;
  }
  @media (min-width: ${breakpoints.xl}) {
    margin-left: 60px;
  }
  @media (min-width: ${breakpoints.max}) {
    margin-left: 80px;
  }
`

const MobileContentContainer = styled.div`
  margin: 20px auto;
  padding: 0 30px;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const HeroTicker = styled.div`
  ${fonts.sofiaProBold}
  font-weight:700;
  text-align: center;
  width: 100vw;
  padding: 15px;
  margin-top: -1px;
  color: ${colors.white};
  background-color: ${colors.charcoalLight};
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const HeaderContainer = styled.div`
  margin: 30px 0 15px;

  @media (min-width: ${breakpoints.md}) {
    margin: ${(props) => (props.margin ? "0 0 40px" : "0 0 20px")};
    max-width: 52vw;
  }
  @media (min-width: ${breakpoints.lg}) {
    max-width: 670px;
  }
`

const Header = styled.h1`
  ${fonts.garamondRegular};
  font-size: 35px;
  line-height: 1.14;
  text-align: center;
  letter-spacing: -0.18px;
`

const Intro = styled.div`
  ${fonts.sofiaPro};
  max-width: 420px;
  margin: auto;
  min-width: 100%;
  margin-bottom: 30px;
  text-align: center;

  white-space: pre-wrap;
  font-size: 18px;
  line-height: 1.33;
  p {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const CopyImage = styled(GatsbyImage)`
  display: none !important;
  max-width: 480px;
  @media (min-width: ${breakpoints.lg}) {
    display: block !important;
  }
  @media (min-width: ${breakpoints.xl}) {
    max-width: 600px;
  }
  @media (min-width: ${breakpoints.xxl}) {
    max-width: 700px;
  }
  @media (min-width: ${breakpoints.max}) {
    max-width: 750px;
  }
`

const CyberMondayHero = ({ tickerContent, heroTicker }) => {
  const { heroImage, tabletHeroImage, mobileImage, copy } =
    useStaticQuery(graphql`
      query CyberMondaySlideQuery {
        heroImage: file(
          relativePath: {
            eq: "blackFriday/cybermonday-extended-interior-desktop.jpg"
          }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1920)
          }
        }
        tabletHeroImage: file(
          relativePath: { eq: "blackFriday/cybermonday-interior-mobile.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1600)
          }
        }
        mobileImage: file(
          relativePath: { eq: "blackFriday/cybermonday-mobile-2.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1600)
          }
        }
        copy: file(relativePath: { eq: "blackFriday/cybermonday-copy.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1600)
          }
        }
      }
    `)
  return (
    <>
      <HeroContainer>
        <MobileImage
          image={mobileImage.childImageSharp.gatsbyImageData}
          alt="Cyber Monday 2023"
        />

        <TabletImage
          image={tabletHeroImage.childImageSharp.gatsbyImageData}
          alt="Cyber Monday 2023"
        />
        <DesktopImage
          image={heroImage.childImageSharp.gatsbyImageData}
          alt="Cyber Monday 2023"
        />
        {/* <Content>
          <CopyImage image={copy.childImageSharp.gatsbyImageData} />
        </Content> */}
      </HeroContainer>
      {heroTicker && <HeroTicker>{tickerContent}</HeroTicker>}
      <MobileContentContainer>
        <HeaderContainer>
          <Header>Cyber Monday - extended!</Header>
        </HeaderContainer>

        <Intro>
          <p>
            Get a FREE limited edition
            <br />
            acetate comb ($24 value)
            <br />
            with any new subscription.
            <br />
            That + more!
          </p>
        </Intro>
      </MobileContentContainer>
    </>
  )
}

export default CyberMondayHero
