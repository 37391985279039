import React, {useEffect, useRef} from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import breakpoints from "../../styles/breakpoints"

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;

  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const MobileImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const Mobile = styled.div`
  display: block !important;

  @media (min-width: ${breakpoints.md}) {
    display: none !important;
  }
`

const Desktop = styled.div`
  display: none !important;

  @media (min-width: ${breakpoints.md}) {
    display: block !important;
  }
`

const Video = ({src, speed, imageMobile, alt, showVideoOnMobile = false, autoplay = true, loading = "lazy"}) => {
  const videoRef = useRef();

  useEffect(() => {
    const video = videoRef.current;

    if (autoplay) {
      video.defaultMuted = true;
      video.setAttribute("muted", "1");
      video.setAttribute("playsinline", "1");
      video.setAttribute("autoplay", "1");
      video.playbackrate = speed ? speed : 1;
    } else {
      video.setAttribute("playsinline", "1");
      video.playbackrate = speed ? speed : 1;
    }
  });

  return (
    <>
      <Mobile>
        {showVideoOnMobile ?
          <VideoContainer>
            {autoplay ?
            <video
              src={src}
              autoPlay
              muted
              loop
              playsInline
              preload="metadata"
              aria-label="video"
              ref={videoRef}/>
            : 
            <video
              src={src}
              loop
              playsInline
              poster={imageMobile ? imageMobile.src : null}
              controls
              preload="metadata"
              aria-label="video"
              ref={videoRef}/>
            }
          </VideoContainer>
          : 
          <MobileImage
            image={imageMobile ? getImage(imageMobile) : null}
            loading={loading}
            alt={alt || ""}
          />
        }
      </Mobile>
      <Desktop>
        <VideoContainer>
          {autoplay ?
          <video
            src={src}
            autoPlay
            muted
            loop
            playsInline
            preload="metadata"
            aria-label="video"
            ref={videoRef}/>
          : 
          <video
            src={src}
            loop
            playsInline
            poster={imageMobile ? imageMobile.src : null}
            controls
            preload="metadata"
            aria-label="video"
            ref={videoRef}/>
          }
        </VideoContainer>
      </Desktop>
    </>
  )
};

export default Video;
