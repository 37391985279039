import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import Slider from "react-slick"
import Hero from "./Hero"
import Arrow from "../../resources/img/ui/icon-chevron.svg"
import FeaturesTicker from "./FeaturesTicker"
import PlpClickableHeroSlide from "../Homepage/PlpClickableHeroSlide"

const HeroContainer = styled.div`
  position: relative;
`

const HeroInnerContainer = styled.div`
  margin-bottom: 40px;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
  }
`

const SliderStyled = styled(Slider)`
  position: relative;
  .slick-arrow {
    z-index: 5;
    background: transparent;
    width: 5%;
    min-width: 40px;
    transform: translate(0, 0);
    top: 0;
    height: calc(100vw / 1.71);
    display: flex;
    align-items: center;
    outline: none;
    opacity: 0.9;
    &:hover,
    &:focus {
      opacity: 0.95;
    }

    @media (min-width: ${breakpoints.md}) {
      height: 100%;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      height: 30px;
      width: 30px;
      background-image: url(${Arrow});
      background-position: center;
      background-repeat: no-repeat;
      background-size: 30px, 30px;
    }
  }

  .slick-prev {
    left: 0;
    &::before {
      transform: rotate(90deg);
      left: 5px;
      @media (min-width: ${breakpoints.lg}) {
        left: 10px;
      }
    }
  }
  .slick-next {
    right: 0;
    &::before {
      transform: rotate(-90deg);
      right: 5px;
      @media (min-width: ${breakpoints.lg}) {
        right: 10px;
      }
    }
  }

  .slick-dots {
    top: ${(props) =>
      props.clickableHero ? "calc(71vw - 38px)" : "calc((100vw / 1.71) - 38px)"};
    height: 30px;
    @media (min-width: ${breakpoints.md}) {
      bottom: 15px;
      top: auto;
    }
    @media (min-width: ${breakpoints.lg}) {
      bottom: 20px;
    }

    li {
      margin: 0px;
      button {
        width: 10px;
        height: 10px;
        padding: 0;
      }
    }

    li button::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border: 1px solid ${colors.black};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: "transparent";
      opacity: 1;
    }
    li.slick-active button::before {
      background: ${colors.black};
    }
  }
`

const Slide = styled.div`
  display: block;
  position: relative;
  outline: none;
`

const PlpHeroCarousel = ({
  slides,
  speed,
  heroTicker,
  tickerContent,
  featuresTickerContent,
}) => {
  let autoplaySpeed = speed ? speed : 4;

  autoplaySpeed *= 1000;

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    fade: false,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplaySpeed: autoplaySpeed,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 820,
        settings: {
          autoplay: true,
        },
      },
    ],
  }

  const isCarousel = true;
  const clickableHero = true;

  const heroSlides = slides?.map((slide) => (
    <Slide key={slide.id}>
      <Hero
        {...slide}
        heroTicker={heroTicker}
        tickerContent={tickerContent}
        clickableHero={clickableHero}
      />
    </Slide>
  ));

  heroSlides.splice(
    1,
    0,
    <Slide key="clickableHero">
      <PlpClickableHeroSlide
        heroTicker={heroTicker}
        tickerContent={tickerContent}
      />
    </Slide>
  )

  return (
    <HeroContainer>
      <HeroInnerContainer>
        {isCarousel ? (
          <SliderStyled {...settings} clickableHero={clickableHero}>
            {heroSlides}
          </SliderStyled>
        ) : (
          // <Hero {...slides[0]} /> 
          null
        )}
      </HeroInnerContainer>
      <FeaturesTicker features={featuresTickerContent} />
    </HeroContainer>
  );
}

export default PlpHeroCarousel;



// import React from "react"
// import styled from "styled-components"
// import breakpoints from "../../styles/breakpoints"
// import colors from "../../styles/colors"
// import Slider from "react-slick"
// import Hero from "./Hero"
// import Arrow from "../../resources/img/ui/icon-chevron.svg"
// import FeaturesTicker from "./FeaturesTicker"
// // import HomeClickableHeroSlide from "./HomeClickableHeroSlide"

// const HeroContainer = styled.div`
//   position: relative;
// `

// const HeroInnerContainer = styled.div`
//   margin-bottom: 40px;
//   @media (min-width: ${breakpoints.md}) {
//     margin-bottom: 0;
//   }
// `

// const SliderStyled = styled(Slider)`
//   position: relative;
//   .slick-arrow {
//     z-index: 5;
//     background: transparent;
//     width: 5%;
//     min-width: 40px;
//     transform: translate(0, 0);
//     top: 0;
//     height: calc(100vw / 1.71);
//     display: flex;
//     align-items: center;
//     outline: none;
//     opacity: 0.9;
//     &:hover,
//     &:focus {
//       opacity: 0.95;
//     }

//     @media (min-width: ${breakpoints.md}) {
//       height: 100%;
//     }

//     &::before {
//       content: "";
//       display: block;
//       position: absolute;
//       height: 30px;
//       width: 30px;
//       background-image: url(${Arrow});
//       background-position: center;
//       background-repeat: no-repeat;
//       background-size: 30px, 30px;
//     }
//   }

//   .slick-prev {
//     left: 0;
//     &::before {
//       transform: rotate(90deg);
//       left: 5px;
//       @media (min-width: ${breakpoints.lg}) {
//         left: 10px;
//       }
//     }
//   }
//   .slick-next {
//     right: 0;
//     &::before {
//       transform: rotate(-90deg);
//       right: 5px;
//       @media (min-width: ${breakpoints.lg}) {
//         right: 10px;
//       }
//     }
//   }

//   .slick-dots {
//     top: ${(props) =>
//             props.clickableHero
//                     ? "calc(71vw - 38px)"
//                     : "calc((100vw / 1.71) - 38px)"};
//     height: 30px;
//     @media (min-width: ${breakpoints.md}) {
//       bottom: 15px;
//       top: auto;
//     }
//     @media (min-width: ${breakpoints.lg}) {
//       bottom: 20px;
//     }

//     li {
//       margin: 0px;
//       button {
//         width: 10px;
//         height: 10px;
//         padding: 0;
//       }
//     }

//     li button::before {
//       content: "";
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//       width: 10px;
//       height: 10px;
//       border: 1px solid ${colors.black};
//       border-radius: 50%;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       background: "transparent";
//       opacity: 1;
//     }
//     li.slick-active button::before {
//       background: ${colors.black};
//     }
//   }
// `

// const Slide = styled.div`
//   display: block;
//   position: relative;
//   outline: none;
// `

// const PlpHeroCarousel = ({
//                         slides,
//                         speed,
//                         heroTicker,
//                         tickerContent,
//                         featuresTickerContent,
// }) => {

//   let autoplaySpeed = speed ? speed : 4

//   autoplaySpeed *= 1000

//   const settings = {
//     dots: true,
//     arrows: true,
//     infinite: true,
//     fade: false,
//     swipe: true,
//     swipeToSlide: true,
//     draggable: true,
//     speed: 800,
//     autoplay: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     initialSlide: 0,
//     autoplaySpeed: autoplaySpeed,
//     pauseOnHover: false,
//     responsive: [
//       {
//         breakpoint: 820,
//         settings: {
//           autoplay: true,
//         },
//       },
//     ],
//   }

//   const isCarousel = slides.length > 1

//   const clickableHero = true

//   const heroSlides = slides.map((slide) => (
//     <Slide key={slide.id}>
//       <Hero
//         {...slide}
//         heroTicker={heroTicker}
//         tickerContent={tickerContent}
//         clickableHero={clickableHero}
//       />
//     </Slide>
//   ))

//   // if (clickableHero) {
//   //   heroSlides.splice(
//   //     1,
//   //     0,
//   //     <Slide key="clickableHero">
//   //       <HomeClickableHeroSlide
//   //         heroTicker={heroTicker}
//   //         tickerContent={tickerContent}
//   //       />
//   //     </Slide>
//   //   )
//   // }

//   return (
//     <HeroContainer>
//       <HeroInnerContainer>
//         {isCarousel ? (
//           <SliderStyled {...settings} clickableHero={clickableHero}>
//             {heroSlides.map((slide) => slide)}
//           </SliderStyled>
//         ) : (
//           <Hero {...slides[0]} />
//         )}
//       </HeroInnerContainer>
//       {<FeaturesTicker features={featuresTickerContent} />}
//     </HeroContainer>
//   )
// }

// export default PlpHeroCarousel
