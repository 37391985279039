import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import Marquee from "react-fast-marquee"
import "../../styles/keyframes.css"

const TickerWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background:${colors.putty};
  padding:20px 0;
  display flex;
  gap:0px;
  margin-top: -10px;
  margin-bottom: 40px;
  z-index:0;
  `


const Blurb = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 5px;
  width: max-content;
  font-size: 18px;
  margin-right: 60px;
  img {
    width: 40px;
    height: 40px;
  }
`

const FeatruesTicker = (props) => {
  const { features } = props

  return (
    <TickerWrapper>
      <Marquee 
        gradient={false}
        speed={60}
        delay={0}>
        {features?.map((elem, idx) => {
            const { icon, label } = elem
          return (
            <Blurb key={idx}>
              <img src={icon.file.url} alt={label || ""} />
              <span>{label}</span>
            </Blurb>
          )
        })}
      </Marquee>
    </TickerWrapper>
  )
}

export default FeatruesTicker
