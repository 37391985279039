import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import { Col, Container, Row } from "styled-bootstrap-grid"
import ResponsiveImage from "./Image/ResponsiveImage"
import { Link, navigate } from "gatsby"
import { ButtonStyleDark } from "./Buttons/Styles"
import { cvgTrack } from "../../services/tracking/converge"


const HeroContainer = styled.div`
  position: relative;
`

const MediaLink = styled(Link)`
  height: fit-content;
  width: fit-content;
  display: block;
`

const HeroImage = styled.div`
  min-height: 300px;
  display: flex;
  div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  @media (min-width: ${breakpoints.md}) {
    div {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    div {
      // width: calc(100% + 680px);
      // height: calc(100% + 40px);
      // top: 0;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    div {
      // width: calc(100%);
    }
  }

  @media (min-width: 1800px) {
    div {
      // width: 100%;
    }
  }

  @media (min-width: ${breakpoints.sm}) {
    min-height: 550px;
    padding-top: 38%;
    position: relative;

  }
`
const HeroContent = styled.div`
  padding: 0 30px;
  margin-bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  @media (min-width: ${breakpoints.sm}) {
    padding: 0 60px;
    margin: ${(props) => (props.first ? "0 0 20px" : "0")};
  }
  @media (min-width: ${breakpoints.md}) {
    display: block;
    text-align: left;
    max-width: 420px;
    box-sizing: border-box;
    padding: 0 0 0 30px;
    margin-bottom: 0;
    margin-top: ${props => props.eyebrow === "true" ? 0 : "30px"};
    min-height: unset;
  }
  
  @media (min-width: ${breakpoints.lg}) {
    margin-top: ${props => props.eyebrow === "true" ? 0 : "72px"};
    max-width: 600px;
    padding: 0 40px;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding: 0 40px 0 55px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px 0 20px;
  min-width: 100%;

  @media (min-width: ${breakpoints.md}) {
    margin: 0;
  }
`

const Eyebrow = styled.div`
  ${fonts.sofiaProBold};
  font-size: 12px;
  line-height: 1.07;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: ${(props) => props.margin && "30px"};
  min-width: 100%;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 0;
    margin-bottom: 30px;
  }
`

const Header = styled.h1`
  ${fonts.garamondRegular};
  font-size: 35px;
  align-self: center;
  line-height: 1;
  letter-spacing: -0.3px;
  color: inherit;
  width: 350px;

  span {
    letter-spacing: 5px;
  }
  @media (min-width: ${breakpoints.md}) {
    color: ${props => props.color ? props.color : "inherit"};
    font-size: 45px;
    text-align: ${props => props.right ? "right" : "left"};
    min-width: 500px;
    align-self: flex-start;

  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 60px;
    letter-spacing: 0;
    min-width: 700px;
    align-self: flex-start;
  }
`

const Description = styled.div`
  ${fonts.sofiaPro};
  font-size: 18px;
  line-height: 1.33;
  max-width: 420px;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  margin: auto;
  min-width: 280px;
  margin-bottom: 40px;
  color: inherit;
  padding-left: 3px;
  width: 280px;
  @media (min-width: ${breakpoints.md}) {
    color: ${props => props.color ? props.color : "inherit"};
    margin-top: 20px;
    margin-left: 0;
    text-align: ${props => props.right ? "right" : "left"};
    margin-bottom: 40px;
    padding-left: 3px;
    width: 420px;
  }
`

const StyledCol = styled(Col)`
  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: ${props => props.right ? "flex-end" : "flex-start"};
  }
`
const CTAs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 20px;
    padding-bottom: 0;
  }
  
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 40px;
    flex-direction:row;
    justify-content: ${(props) => (props.right ? "flex-end" : "flex-start")};
  }
`

const ButtonContainer = styled.div`
  margin-bottom: 15px;
  :last-of-type {
    margin-bottom: 0;
  }

  a {
    max-width: 280px;
    width: 100% !important;
    // background: ${(props) => props.mobileBackground && props.mobileBackground};
    // border-color: ${(props) => props.mobileBorder && props.mobileBorder};
    // color: ${(props) => props.mobileColor && props.mobileColor};
  }

  a:hover {
      // background: ${(props) => props.hoverBcg && props.hoverBcg};
      // border-color: ${(props) => props.hoverBorder && props.hoverBorder};
      // color: ${(props) => props.hoverColor && props.hoverColor};
    }
  @media (max-width:${breakpoints.sm}){
    text-align: center;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 12px;
    :last-of-type {
      margin-bottom: 0;
    }

    a {
      margin-left: 0;
      max-width: 280px;
      // background: ${(props) => props.background && props.background};
      // border-color: ${(props) => props.border && props.border};
      // color: ${(props) => props.color && props.color};
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 0;
    margin-right: 20px;
    min-width: 230px;
    :last-of-type {
      margin-right: 0;
    }
  }
`

const ButtonDark = styled.a`
  ${ButtonStyleDark}
`

const HeroBlockAllProduct = (props) => {

  const {
    media,
    imageMobile,
    header,
    eyebrow,
    description,
    textPositionMobile,
    isMediaCta,
    mediaUrl,
    textPositionDesktop,
    ctas,
    headerTextColor,
    introTextColor
  } = props

  const mediaLink = isMediaCta && mediaUrl
  const isTextRight = textPositionDesktop === false

  const heroImageContent = () => (
      <HeroImage>
        <ResponsiveImage
          imageMobile={imageMobile}
          imageDesktop={media}
          loading={"eager"}
          alt={header || ""}
        />
      </HeroImage>
  )

  const handleButtonClick = (event, label) => {
    event.preventDefault()

    cvgTrack({
      eventName: 'Hero button click',
      properties: {
        label: label
      }
    })

    navigate(event.target.href)
  }

  return (
    <HeroContainer>
      <Container fluid>
        <Row>
          <Col>
          {mediaLink ?
            <MediaLink to={mediaUrl}>
                {heroImageContent()}
            </MediaLink>
            :
            heroImageContent()
          }
          </Col>
          <StyledCol
            xsOrder={textPositionMobile ? "first" : "last"}
            smOrder={textPositionMobile ? "first" : "last"}
            right={isTextRight ? 1 : 0}
          >
            <HeroContent first={textPositionMobile ? 1 : 0} eyebrow={eyebrow ? 1 : 0} >
              {eyebrow && (
                <Eyebrow margin={!textPositionMobile ? 1 : 0}>
                  {eyebrow}
                </Eyebrow>
              )}
              <HeaderContainer>
                <Header dangerouslySetInnerHTML={{ __html: header }} right={isTextRight ? 1 : 0} color={headerTextColor ? headerTextColor : null} />
              </HeaderContainer>
              {description && <Description right={isTextRight ? 1 : 0} dangerouslySetInnerHTML={{ __html : description}} color={introTextColor ? introTextColor : null} ></Description>}
              {ctas && ctas.length > 0 && (
               <CTAs right={isTextRight ? 1 : 0}>
                {ctas.map((btn, index) => {
                    return(
                    <ButtonContainer key={index}>
                      <ButtonDark href={btn.url} onClick={(e) => handleButtonClick(e, btn.label)}>{btn.label}</ButtonDark>
                    </ButtonContainer>
                    )
                  })
                }
              </CTAs>

              )}
            </HeroContent>
          </StyledCol>
        </Row>
      </Container>
    </HeroContainer>
  )
}

export default HeroBlockAllProduct
