import React, { Suspense, useEffect, useRef, useState } from "react"
import HeroBlock from "../Core/HeroBlock"
import styled from "styled-components"
import Fallback from "../Core/Fallback"
import FeaturesTicker from "./FeaturesTicker"
import ClickableHeroSlide from "../Homepage/ClickableHeroSlide"
import BlackFridayHero from "../Homepage/BlackFridayHero"
import CyberMondayHero from "../Homepage/CyberMondayHero"
import HeroBlockAllProduct from "../Core/HeroBlockAllProduct"
import PlpHeroCarousel from "./PlpHeroCarousel";

const QuizCtaBlock = React.lazy(() => import("../Core/QuizCtaBlock"))
const ProductsCallout = React.lazy(() => import("./ProductsCallout"))
const ReviewCarousel = React.lazy(() => import("../Homepage/ReviewCarousel"))
const ExploreOurRange = React.lazy(() => import("../PLP/ExploreOurRange"));
const DoctorBlock = React.lazy(() => import("../Homepage/DoctorBlock"));

const Background = styled.div`
  // background-image: linear-gradient(0deg,#fff 43%,#fffffe 0,#f7f5f0 86%);
  background-color: #fff;
`

const PageContents = ({
  blocks,
  features,
  allProductsPlp,
}) => {
  let pageBlocks = []
  let exploreOurRangeInserted = false;
  let doctorBlockInserted = false;
  const isSSR = typeof window === "undefined"
  const [pathname, setPathname] = useState("");
  useEffect(() => {
    if (!isSSR) {
      setPathname(window.location.pathname);
    }

  }, [isSSR]);
  let productCalloutCount = 0;
  const singleProductsBlockRef = useRef(null)
  const setsProductsBlockRef = useRef(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    const categoryFilter = searchParams.get("category");
    const concernFilter = searchParams.get("concern");

    if (categoryFilter) {
      setTimeout(() => {
        executeSingleProductsScroll();
      }, 1500);
    }

    if (concernFilter) {
      setTimeout(() => {
        executeSetsScroll();
      }, 1500);
    }
  }, [])

  const executeSingleProductsScroll = () => singleProductsBlockRef.current.scrollIntoView()
  const executeSetsScroll = () => setsProductsBlockRef.current.scrollIntoView()

  blocks && blocks.forEach((block, index) => {
    let { __typename: type } = block
    const nextBlock = blocks[index + 1]
    let isQuizNext = nextBlock && nextBlock.__typename === "ContentfulQuizCallout"

    if (type === "ContentfulHeroBlock") {
      pageBlocks.push(<HeroBlockAllProduct {...block} key={index} />);
      pageBlocks.push(
        <FeaturesTicker features={features} key={`${index}-1`} />
      );
    }

    if (type === "ContentfulHeroCarousel") {
      if (pathname === "/products") {
        pageBlocks.push(<PlpHeroCarousel {...block} key={index} />);
      }
    }

    if (type === "ContentfulProductsCallout") {
      productCalloutCount = productCalloutCount + 1;

      if (productCalloutCount === 2) {
        pageBlocks.push(<div key={index} ref={singleProductsBlockRef}>{!isSSR && <Suspense fallback={<Fallback />}><ProductsCallout {...block} quizNext={isQuizNext} /></Suspense>}</div>)
      } else {
        pageBlocks.push(<div key={index} ref={setsProductsBlockRef}>{!isSSR && <Suspense fallback={<Fallback />}><ProductsCallout {...block} quizNext={isQuizNext} /></Suspense>}</div>)
      }
    }

    if (type === "ContentfulReviewsCarousel") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback />}><ReviewCarousel {...block} /></Suspense>}</React.Fragment>);
    }

    if (type === "ContentfulQuizCallout") {

      if (!exploreOurRangeInserted) {
        pageBlocks.push(<React.Fragment key="explore-our-range">{!isSSR && <Suspense fallback={<Fallback />}><ExploreOurRange params={pathname} /></Suspense>}</React.Fragment>);
        exploreOurRangeInserted = true;
      }
      if (!doctorBlockInserted) {
        pageBlocks.push(<React.Fragment key="doctor">{!isSSR && <Suspense fallback={<Fallback />}><DoctorBlock /></Suspense>}</React.Fragment>);
        doctorBlockInserted = true;
      }
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback />}><QuizCtaBlock {...block} plp /></Suspense>}</React.Fragment>)


    }
  })

  return (
    <React.Fragment>
      <Background>{pageBlocks}</Background>
    </React.Fragment>
  )
}

export default PageContents
