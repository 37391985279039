import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import { GatsbyImage } from "gatsby-plugin-image"
import ButtonDark from "../Core/Buttons/ButtonDark"
import Text from "../../resources/img/hero-test/hero-text.gif"
import { useStaticQuery, graphql, Link } from "gatsby"
import Mobile from "../../resources/img/hero-test/spend-tiers-mobile.gif"

const HeroContainer = styled.div`
  position: relative;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 80px;
  }
`

const BackgroundImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  display: flex;

  div {
    max-width: unset !important;
    width: 100%;
    height: 100%;
  }
`

const MobileImageLink = styled(Link)`
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const MobileImage = styled.img`
  height: 100%;
  width: 100%;
  @media (min-width: ${breakpoints.md}) {
    display: none !important;
  }
`

const TabletImage = styled(BackgroundImage)`
  display: none !important;
  @media (min-width: ${breakpoints.md}) {
    display: flex !important;
  }
  @media (min-width: ${breakpoints.lg}) {
    display: none !important;
  }
`

const DesktopImage = styled(BackgroundImage)`
  display: none !important;
  @media (min-width: ${breakpoints.lg}) {
    display: flex !important;
  }
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  top: 0%;
  left: 0;
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: flex;
  }
`

const ImageContainer = styled.div`
  height: 30%;
  width: max-content;
  display: flex;
  width: fit-content;
  max-width: 65%;
  justify-content: center;

  img {
    object-fit: contain;
    width: 100%;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 50%;
    height: 50%;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 30px;

  @media (min-width: ${breakpoints.md}) {
    a,
    button {
      padding: 20px 40px;
      height: fit-content;
      width: fit-content;
    }
  }
`

const MobileContentContainer = styled.div`
  margin: 20px auto;
  padding: 0 30px;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const HeroTicker = styled.div`
  ${fonts.sofiaProBold}
  font-weight:700;
  text-align: center;
  width: 100vw;
  padding: 15px;
  margin-top: -1px;
  color: ${colors.white};
  background-color: ${colors.charcoalLight};
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`


const HeaderContainer = styled.div`
  margin: 30px 0 15px;

  @media (min-width: ${breakpoints.md}) {
    margin: ${(props) => (props.margin ? "0 0 40px" : "0 0 20px")};
    max-width: 52vw;
  }
  @media (min-width: ${breakpoints.lg}) {
    max-width: 670px;
  }
`

const Header = styled.h1`
  ${fonts.garamondRegular};
  font-size: 35px;
  line-height: 1.14;
  text-align: center;
  letter-spacing: -0.18px;
  
  i {
    ${fonts.garamondItalic};
  }
`

const Intro = styled.div`
  ${fonts.sofiaPro};
  max-width: 420px;
  margin: auto;
  min-width: 100%;
  margin-bottom: 30px;
  text-align: center;

  white-space: pre-wrap;
  font-size: 18px;
  line-height: 1.33;
  p {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const ClickableHeroSlide = ({ tickerContent, heroTicker, isPlp = false }) => {
  const { heroImage, tabletHeroImage } = useStaticQuery(graphql`
    query ClickableHeroSlideQuery {
      heroImage: file(
        relativePath: { eq: "hero-test/spend-tiers-desktop-still.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 1920)
        }
      }
      tabletHeroImage: file(
        relativePath: { eq: "hero-test/spend-tiers-mobile-still.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 1600)
        }
      }
    }
  `)
  return (
    <>
      <HeroContainer>
        {isPlp ? (
            <MobileImage
              src={Mobile}
              alt="5%, orders of $75+, 10%, orders of $100+, 15%, orders of $130+"
            />
        ) : (
          <MobileImageLink to="/products">
            <MobileImage
              src={Mobile}
              alt="5%, orders of $75+, 10%, orders of $100+, 15%, orders of $130+"
            />
          </MobileImageLink>
        )}
        <TabletImage image={tabletHeroImage.childImageSharp.gatsbyImageData} alt=""/>
        <DesktopImage image={heroImage.childImageSharp.gatsbyImageData} alt=""/>
        <Content>
          <ImageContainer>
            {isPlp ? (
              <img
                src={Text}
                alt="5%, orders of $75+, 10%, orders of $100+, 15%, orders of $130+"
              />
            ) : (
              <Link to="/products">
                <img
                  src={Text}
                  alt="5%, orders of $75+, 10%, orders of $100+, 15%, orders of $130+"
                />
              </Link>
            )}
          </ImageContainer>

          {!isPlp && (
            <ButtonContainer>
              <ButtonDark to="/products">Start Shopping</ButtonDark>
            </ButtonContainer>
          )}
        </Content>
      </HeroContainer>
      {heroTicker && <HeroTicker>{tickerContent}</HeroTicker>}
      <MobileContentContainer>
        <HeaderContainer>
          <Header>Works hard. Plays hard.</Header>
        </HeaderContainer>

        <Intro>
          <p>Your healthiest scalp and hair, guaranteed.</p>
        </Intro>

        {!isPlp && <ButtonDark to="/products">Start Shopping</ButtonDark>}
      </MobileContentContainer>
    </>
  )
}

export default ClickableHeroSlide
